
import Menu from './Menu'
import Hamburger from './Hamburger'
import React from "react"

const Navbar = ({toggleHamburger, onToggle}) => {

    return (
        <div id='navbar-resp-dummy'>
            <div className='navbar-container'>
                <Hamburger id={'hamb-menu'} toggleHamburger={toggleHamburger} onToggle={onToggle} />
                <div id='navbarItemsID'>
                    <Menu home_id='home-btn' last_id='last-btn' isTransitionMenu={false} />
                </div>
            </div>
        </div>
    )
}

export default Navbar
