import './App.scss';
import Header from './components/header/Header'
import Body from './components/body/Body'
import Footer from './components/footer/Footer'
import Menu from './components/header/Menu'
import ImageFullView from './components/body/ImageFullView'
import Hamburger from './components/header/Hamburger'

import { BrowserRouter as Router } from 'react-router-dom'
import { useState, useEffect } from 'react'

function App() {

  const [toggleMenu, setToggle] = useState(false)
  const [fullViewImages, setFullViewImage] = useState({
    album: [],
    img: null,
    index: 0
  })
  const [isFullViewVisible, setFullViewVisibility] = useState('')
  const [marginBody, setMargin] = useState(0)

  const onToggle = () => {
    setToggle(!toggleMenu)
  }

  const openFullViewImage = (obj) => {
    setFullViewImage(obj)
    setFullViewVisibility(true)
  }

  const closeFullViewImage = () => {
    setFullViewImage({
      album: [],
      img: null,
      index: 0
    })
    setFullViewVisibility(false)
  }

  const handleResize = () => {
    if(window.innerWidth > 650 && toggleMenu) 
      setToggle(false)
    setMargin(document.getElementsByTagName('footer')[0].offsetHeight)
  }

  useEffect(() => {
    handleResize()
  }, []);
  
  window.addEventListener('resize', handleResize)
  
  return (
    <Router>
      <div id='rootDiv'>
        <Header toggleHamburger={toggleMenu} onToggle={onToggle} />
        <Body openFullViewImage={openFullViewImage} marginBody={marginBody} />
        <Footer />
        <div id='transition-menu' className={toggleMenu ? 'menu_active' : null}>
          <div>
            <Hamburger id={'menu-cross'} toggleHamburger={true} onToggle={onToggle} />
          </div>
          <Menu isTransitionMenu={true} hideMenu={onToggle} />
        </div>
        <ImageFullView fullViewImages={fullViewImages} visible={isFullViewVisible} closeView={closeFullViewImage} />
      </div>
    </Router>
  );
}

export default App;
