import React from 'react'
import { text_content } from '../../resources/texts/site_content' 
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'

const AddressPanel = () => {
    return (
        <div id='address-panel'>
            <div>
                <div className='icon-container'>
                    <FaMapMarkerAlt />
                </div>

                <div className='address-container'>
                    <h3>{text_content.address.title}</h3>
                    
                    <div>
                        <p>{text_content.address.hq.title}: </p>
                        <p>{text_content.address.hq.address}</p>
                    </div>

                    <div>
                        <p>{text_content.address.wp.title}: </p>
                        <p>{text_content.address.wp.address}</p>
                    </div>

                    <p>{text_content.address.city.name}, {text_content.address.county.name}, {text_content.address.country.name}</p>
                </div>
            </div>

            <div>
                <div class='icon-container'>
                    <FaPhoneAlt />
                </div>

                <div className='phone-container'>
                    <h3>{text_content.contact.tel.title}</h3>

                    {
                        text_content.contact.tel.tels_nr.map((el1, index) =>
                            
                            <>
                                <div>
                                    <p>{el1.name}: </p>
                                    <div>
                                        {
                                            el1.tels.map(el2 => 
                                                <p>{el2}</p>
                                            )
                                        }
                                    </div>
                                </div>

                                {
                                    text_content.contact.tel.tels_nr.length - 1 !== index ? 
                                    <hr /> : ''
                                }
                            </>
                        )
                    }
                </div>
            </div>

            <div>
                <div class='icon-container'>
                    <FaEnvelope />
                </div>

                <div>
                    <h3>{text_content.contact.email.title}</h3>
                    <p>{text_content.contact.email.email}</p>
                </div>
            </div>
        </div>
    )
}

export default AddressPanel
