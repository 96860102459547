
import Map from './Map'
import ContactForm from './ContactForm'
import DetailsPanel from './DetailsPanel'
import { text_content } from '../../resources/texts/site_content'

const Contact = () => {
    return (
        <div>
            <div id='contact-parent'>
                <div class='page-container'>
                    <div id='contact-text'>
                        <h1>{text_content.contact.title}</h1>
                        <p className='justify'>{text_content.contact.text}</p>
                    </div>
                </div>

                <div class='page-container' id='contact-page-container'>
                    <div>
                        <div id='contact-container'>
                            <ContactForm />
                            <DetailsPanel />
                        </div>
                    </div>
                </div>
            </div>

            <Map />
        </div>
    )
}

export default Contact
