import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import { getMeDetails, getMeDetailsById } from '../../resources/texts/site_content'
import {
    scroller
} from "react-scroll"
import { sleep } from '../../resources/globals/methods'

const ServiceDetails = () => {
    const location = useLocation()
    const [content, setContent] = useState(null)
    const [todo, setTodo] = useState(false)

    const scrollTo = async (goTo, offset) => {
        await sleep(200)

        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset
        });
    }

    useEffect(() => {
        if(location.state) {
            if(location.state.parent === 'services') {
                setContent(getMeDetails(location.state.content))
            }
        }else {
            const id = location.pathname.split('/')[2]
            setContent(getMeDetailsById(id))
            scrollTo('services-details', -150)
        }
    }, [location.pathname])

    useEffect(() => {
        handleResize()
    })

    const handleResize = () => {
        let elements = document.getElementsByClassName('img-id')

        for(let el of elements) {
            const orig = el.getAttribute('original-pos')
            const alt = el.getAttribute('alt-pos')

            if(orig !== alt) {
                if(window.innerWidth <= 1000 && todo === false) {
                    el.classList.add(alt)
                    el.classList.remove(orig)
                    setTodo(true)
                }else if(window.innerWidth > 1000 && todo === true) {
                    el.classList.add(orig)
                    el.classList.remove(alt)
                    setTodo(false)
                }
            }
        }
    }
      
    window.addEventListener('resize', handleResize)

    return (
        <div id='services-details' className='page-container'>
            <div style={{padding: '35px 0'}}>
            {
                content ? 
                <>
                <h1>{content.title}</h1>

                {
                    content.description.map(el => 
                        <p className='justify'>{el}</p>
                    )
                }
    
                <ul style={{marginTop: '15px'}}>
                {
                    content.sub.map(el => 
                        <li className='justify'>{el.title}</li>
                    )
                }
                </ul>

                <div>
                    <div>
                    {
                        content.details.presentation.map(el => 
                            <>
                            <h3>{el.title}</h3>

                            <div className='presentation-grid'>
                                <div className={el.images.length === 0 ? 'fullspan' : 'center'}>
                                {
                                    el.content.map(elc =>
                                        <>
                                        {
                                            typeof elc != 'string' ?
                                            elc.image ?
                                            <div className='presentation-grid'>
                                                <div className='center'>
                                                    <p>{elc.content}</p>
                                                    <ul>
                                                    {
                                                        elc.sub.map(elcs =>
                                                            <li className='justify'>{elcs}</li>
                                                        )
                                                    }
                                                    </ul>
                                                </div>

                                                {
                                                    elc.image ?
                                                    <div className={elc.image['pos-type'] + ' img-id'} original-pos={elc.image['pos-type']} alt-pos={elc.image['alt']}>
                                                        <img src={require('../../resources/albums/Presentation/' + elc.image.src).default} />
                                                    </div> : ''
                                                }
                                            </div>
                                            :
                                            <>
                                            <p className='justify'>{elc.content}</p>
                                            <ul>
                                            {
                                                elc.sub.map(elcs =>
                                                    <li className='justify'>{elcs}</li>
                                                )
                                            }
                                            </ul>
                                            </>
                                            :
                                            <p className='justify'>{elc}</p>
                                        }
                                        </>
                                    )
                                }
                                </div>

                                {
                                    el.images.map(img =>
                                        <div class={img['pos-type'] + ' img-id'} original-pos={img['pos-type']} alt-pos={img['alt']}>
                                            <img src={require('../../resources/albums/Presentation/' + img.src).default} />
                                        </div>  
                                    )
                                }
                            </div>

                            <ul>
                            {
                                el.sub.map(els =>
                                    <li className='justify'>
                                        <h5>{els.title}</h5>

                                        <div>
                                            <div>
                                            {
                                                els.content.map(elsc =>
                                                    <>
                                                    {
                                                        typeof elsc != 'string' ?
                                                        <>
                                                        <div>
                                                            <p className='justify'>{elsc.content}</p>
                                                            <ul>
                                                            {
                                                                elsc.sub.map(elscs =>
                                                                    <li className='justify'>{elscs}</li>
                                                                )
                                                            }
                                                            </ul>
                                                        </div>

                                                        <></>

                                                        </>
                                                        :
                                                        <p className='justify'>{elsc}</p>
                                                    }
                                                    </>
                                                )
                                            }
                                            </div>

                                            {
                                                els.images.map(img =>
                                                    <></>    
                                                )
                                            }
                                        </div>

                                        <ul>
                                        {
                                            els.sub.map(elss =>
                                                <li>
                                                    <p className='justify'>{elss}</p>
                                                </li> 
                                            )
                                        }
                                        </ul>
                                    </li> 
                                )
                            }
                            </ul>
                            </>
                        )
                    }
                    </div>

                    {
                        content.details.images.map(img =>
                            <>
                            
                            </>  
                        )
                    }
                </div>
                </>
                : ''
            }
            </div>
        </div>
    )
}

export default ServiceDetails
