import React from 'react'

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { Link as RLink } from 'react-router-dom'
import { text_content } from '../../resources/texts/site_content'

import { FaAngleRight, FaAngleDown } from 'react-icons/fa'

import { getMeRouterObject } from '../../resources/routes/RouteMethods'
import { getRoutes } from '../../resources/routes/Routes'

import {
    scroller
} from "react-scroll"
import { sleep } from '../../resources/globals/methods'

const RouteHistory = () => {
    const location = useLocation()
    
    const [pathArray, setPath] = useState([{
        route: '/',
        title: text_content.home.title,
        parent: null
    }])
    const [resp, setResp] = useState(false)
    const [toshow, setToshow] = useState(false)

    useEffect(() => {
        const routes = getRoutes()
        const path = location.pathname.split('/')
        let titlePathArray = []
        let parent = 'home'
        let obj = {
            route: '/',
            title: routes[parent].title,
            parent: null
        }
        titlePathArray.push(obj)

        if(path[1] !== path[0]) {
            path.forEach((el, index) => {
                if(index !== 0) {
                    if(parent === 'home') {
                        let obj = {
                            route: '/' + routes[el].path,
                            title: routes[el].title,
                            parent: null
                        }
                        titlePathArray.push(obj)
                        parent = el
                    }else {
                        routes[parent].sub.forEach(sel => {
                            if(sel.path === el) {
                                let obj = {
                                    route: '/' + sel.path,
                                    title: sel.title,
                                    parent: parent
                                }
                                titlePathArray.push(obj)
                                parent = el
                            }
                        })
                    }
                }
            })
        }

        setPath(titlePathArray)
    }, [location.pathname]);

    useEffect(() => {
        handleResize()
    })

    const handleResize = () => {

        if(window.innerWidth <= 650 && resp === false) {
            setResp(true)
        }else if(window.innerWidth > 650 && resp === true) {
            setResp(false)
        }
    }
      
    window.addEventListener('resize', handleResize)
    window.addEventListener('click', (e) => {
        if(toshow === true) {
            if (outsideClick(e, document.getElementById('history-route').children[0])) {
                setToshow(false)
            }
        }
    })

    function outsideClick(event, notelem)	{
        var clickedOut = true
        if (event.target === notelem || notelem.contains(event.target)) {
            clickedOut = false
        }
        return clickedOut
    }

    const scrollTo = async (goTo, offset) => {
        setToshow(!toshow)

        await sleep(200)

        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset
        });
    }

    return (
        <div id='history-route'>
            <div className='page-container'>
                <div>
                    {
                        resp === false || pathArray.length === 1 ?
                        <div id='reg-route'>
                        {
                            pathArray.map(el => 
                               <> 
                                <RLink className='icon_link history-link' style={{color: 'white', textDecoration: 'none'}} to={() => getMeRouterObject(el.route, el.parent, null)} onClick={() => scrollTo('page-main-content', -150)}>
                                    {el.title}
                                </RLink>
                                <FaAngleRight class='history-icon' /> 
                               </>
                            )
                        } 
                        </div>
                        :
                        <div id='resp-route'>
                            <div onClick={() => setToshow(!toshow)}>
                                <p style={{display: 'inline-block'}}>
                                    {pathArray[pathArray.length - 1].title}
                                </p>
                                <FaAngleDown style={{transition: 'all 0.25s linear', transform: toshow ? `rotate(-180deg)` : ''}} />
                            </div>
                            <div id='route-cont' style={{opacity: toshow ? '1': '0', visibility: toshow ? 'visible': 'hidden'}}>
                            {
                                pathArray.map((el, index) => 
                                    index !== pathArray.length - 1 ?
                                    <> 
                                    <RLink className='icon_link history-link' style={{color: 'white', textDecoration: 'none'}} to={() => getMeRouterObject(el.route, el.parent, null)} onClick={() => scrollTo('page-main-content', -150)}>
                                        {el.title}
                                    </RLink>
                                    <FaAngleRight class='history-icon' /> 
                                    </> : ''
                                )
                            }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default RouteHistory
