import React from 'react'

import { Link as RLink } from 'react-router-dom'
import {
    scroller
} from "react-scroll"
import { getMeRouterObject } from '../../resources/routes/RouteMethods'

import { sleep } from '../../resources/globals/methods'

const Dropdown = ({id, elements, isTransitionMenu, hideMenu}) => {

    const scrollTo = async (goTo, offset) => {
        await sleep(200)

        if(isTransitionMenu === true) {
            hideMenu()
        }

        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset
        })
    }

    return (
        <div id={id} className={`dropdown-container`}>
            {
                elements.map(el => 
                    <RLink to={() => getMeRouterObject('/services', null, null)} onClick={() => scrollTo(el.id, -100)}>
                        <div className='dropdown_el'>
                            <p>
                                {el.title}
                            </p>
                        </div>
                    </RLink>
                )
            }
        </div>
    )
}

export default Dropdown
