import React from 'react'

import { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'

import { text_content } from '../../resources/texts/site_content' 
import TableCustom from './TableCustom'

const Experience = () => {

    const [experience, setExperience] = useState({
        succ: false,
        cont: []
    })

    const getExperience = async () => {
        const res = await fetch('https://esttubapi.herokuapp.com/get-experience')
        //const res = await fetch('http://localhost:30001/get-experience')
        const res_data = await res.json()
        
        if(res_data.Success === true) {
            const obj = {
                succ: true,
                cont: res_data.Content
            }
            
            setExperience(obj)
            window.sessionStorage.setItem("experience", JSON.stringify(obj))
        }else {
            const obj = {
                succ: false,
                cont: [res_data]
            }
            
            setExperience(obj)
        }
    }

    useEffect(() => {
        if(window.sessionStorage.getItem("experience") == null) {
            getExperience()
        }else {
            setExperience(JSON.parse(window.sessionStorage.getItem("experience")))
        }
    }, [])

    return (
        <div>
            <div id='experience-text'>
                <h1>{text_content.experience.title}</h1>
                <p className='justify'>{text_content.experience.text}</p>
            </div>
            {
                experience.cont.length > 0 || experience.succ === true ? 
                <>
                    {
                        experience.succ ?
                        <div style={{paddingBottom: '35px'}}>
                            <TableCustom data={experience.cont} />
                        </div>
                        :
                        <div class='center-with-padding'>
                            <p>{experience.cont[0].Message}</p>
                        </div>
                    } 
                </>:
                <div class='center-with-padding'>
                    <CircularProgress />
                </div>
            }
        </div>
    )
}

export default Experience
