import React from 'react'

import CardCustom from './CardCustom.js'

import { useState, useEffect } from 'react'

import Carousel from 'react-bootstrap/Carousel'

const CardSlider = ({content, nosc}) => {

    const [nrel, setNumberOfElements] = useState(nosc)

    const auxAlbum = [...content]
    const auxCurr = [...content]
    const auxPrev = [...content]
    const auxNext = [...content]

    auxCurr.splice(nrel, auxCurr.length)

    auxPrev.splice(nrel - 1, auxPrev.length)
    auxPrev.unshift(auxAlbum[auxAlbum.length - 1])

    auxNext.splice(0, 1)
    auxNext.splice(nrel, auxNext.length)

    const [originalAlbum, setOriginalAlbum] = useState(auxAlbum)
    const [sliderAlbum, setAlbum] = useState(auxCurr)
    const [prevStateAlbum, setPrevAlbum] = useState(auxPrev)
    const [nextStateAlbum, setNextAlbum] = useState(auxNext)
    const [index, setIndex] = useState(1);
    const [shouldSlide, setShouldSlide] = useState(true)
    const [image_height, setImageHeight] = useState(200)
    const [image_width, setImageWidth] = useState(225)

    useEffect(() => {
        if (nosc !== nrel) {
            setNumberOfElements(nosc);

            const arr = [...originalAlbum]
            setOriginalAlbum(arr)
    
            const arrCurr = [...arr]
            const arrPrev = [...arr]
            const arrNext = [...arr]

            arrCurr.splice(nosc, arrCurr.length)

            arrPrev.splice(nosc - 1, arrPrev.length)
            arrPrev.unshift(arr[arr.length - 1])

            arrNext.splice(0, 1)
            arrNext.splice(nosc, arrNext.length)
    
            setPrevAlbum(arrPrev)
            setAlbum(arrCurr)
            setNextAlbum(arrNext)
        }
    }, [nosc]);
  
    const handleSelect = (selectedIndex, e) => {
        
        setIndex(selectedIndex);
    }

    const slideLeft = async () => {

        const arr = [...originalAlbum]
        const el = arr.splice(0, 1)
        arr.push(el[0])
        setOriginalAlbum(arr)

        const arrCurr = [...arr]
        const arrPrev = [...arr]
        const arrNext = [...arr]

        arrCurr.splice(nosc, arrCurr.length)
    
        arrPrev.splice(nosc - 1, arrPrev.length)
        arrPrev.unshift(arr[arr.length - 1])

        arrNext.splice(0, 1)
        arrNext.splice(nosc, arrNext.length)

        setPrevAlbum(arrPrev)
        setAlbum(arrCurr)
        setNextAlbum(arrNext)
        setShouldSlide(false)
        setIndex(1)
    }

    const slideRight = () => {

        const arr = [...originalAlbum]
        const el = arr.splice(arr.length - 1, 1)
        arr.splice(0, 0, el[0])
        setOriginalAlbum(arr)

        const arrCurr = [...arr]
        const arrPrev = [...arr]
        const arrNext = [...arr]

        arrCurr.splice(nosc, arrCurr.length)

        arrPrev.splice(nosc - 1, arrPrev.length)
        arrPrev.unshift(arr[arr.length - 1])

        arrNext.splice(0, 1)
        arrNext.splice(nosc, arrNext.length)

        setPrevAlbum(arrPrev)
        setAlbum(arrCurr)
        setNextAlbum(arrNext)
        setShouldSlide(false)
        setIndex(1)
    }

    const onSlide = (eventKey, direction) => {
        if(shouldSlide === true) {
            if(direction === 'right') {
                slideRight()
            }else if(direction === 'left') {
                slideLeft()
            }
        }else
            setShouldSlide(true)
    }

    const handleCardImageHeightSize = () => {
        const imgs = document.getElementsByClassName('card-image')
        let avg = 0, count = 0;
        for (let element of imgs) {
            avg += element.offsetWidth;
            count++
        }
        avg /= count
        setImageHeight(avg + 75)
        setImageWidth(avg)
    }
    
    window.addEventListener('resize', handleCardImageHeightSize)

    return (
        <>
            <Carousel id='homeCardSlider' 
                    indicators={false}
                    interval={null}
                    onSlid={onSlide}
                    onSelect={handleSelect}
                    activeIndex={index}
                    touch={false}
                    slide={shouldSlide} >

                <Carousel.Item>
                    <div className='card-slider-container'>
                        {
                            prevStateAlbum.map(el =>
                                <CardCustom element={el} image_width={image_width} image_height={image_height} />    
                            )
                        }
                    </div>
                </Carousel.Item> 

                <Carousel.Item>
                    <div className='card-slider-container'>
                        {
                            sliderAlbum.map(el =>
                                <CardCustom element={el} image_width={image_width} image_height={image_height} />    
                            )
                        }
                    </div>
                </Carousel.Item>    

                <Carousel.Item>
                    <div className='card-slider-container'>
                        {
                            nextStateAlbum.map(el =>
                                <CardCustom element={el} image_width={image_width} image_height={image_height} />    
                            )
                        }
                    </div>
                </Carousel.Item>  
            </Carousel>
        </>
    )
}

export default CardSlider
