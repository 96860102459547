
import './Body.scss'

import Services from './Services'
import Notice from './Notice'

import { Route } from 'react-router-dom'
import React, { PureComponent } from 'react'

import { routes } from '../../resources/routes/Routes'
import { text_content } from '../../resources/texts/site_content'

class Body extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            openFullViewImage: props.openFullViewImage,
            marginBody: props.marginBody
        };
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log(this.props.openFullViewImage)
    //     return false;
    // }

    render(){

        return (
            <main id='page-main-content' style={{marginBottom: `${this.props.marginBody}px`}}>
                <Route path={`/${routes.home.path}`} exact component={routes.home.component} />
                <Route path={`/${routes.services.path}`} 
                    render={({match: { url }}) =>
                        <>
                            <Route path={`${url}`}
                                render={(props) => 
                                <Services openFullViewImage={this.props.openFullViewImage} />} 
                            exact />

                            {
                                routes.services.sub.map(el => 
                                    <Route path={`${url}/${el.path}`} component={el.component} />
                                )
                            }
                        </>
                    } 
                />
                
                <div className='page-container'>
                    <div>
                        <Route path={`/${routes.experience.path}`} component={routes.experience.component} />
                    </div>
                </div>

                {
                    text_content['firm_rights'].notices.map(r => 
                        <Route path={`/${r.id}`} component={Notice} />
                    )
                }

                <Route path={`/${routes.contact.path}`} component={routes.contact.component} />
            </main>
        )
    }
}

export default Body
