import React from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';

const TableCustom = ({data}) => {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    return (
        <>
        {
            data.length > 0 ? 
            <Paper>
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    data[0].map(el => 
                                        <TableCell>{el}</TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
        
                        <TableBody>
                            {
                                data.slice(page * rowsPerPage + 1, page * rowsPerPage + rowsPerPage + 1).map((row) => (
                                    <TableRow key={row[0]}>
                                        {
                                            row.map(cell => 
                                                <TableCell component="th" scope="row">
                                                    {cell}
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer> 

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    component="div"
                    count={data.length - 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>: ''
        }
        </>
    )
}

export default TableCustom
