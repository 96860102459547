import React from 'react'

import { Link as RLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { getMeRouterObject } from '../../resources/routes/RouteMethods'

import { sleep } from '../../resources/globals/methods'

import {
    scroller
} from "react-scroll"

import { text_content } from '../../resources/texts/site_content'

const Service = ({element}) => {
    const location = useLocation()

    const scrollTo = async (goTo, offset) => {
        await sleep(200)

        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset
        })
    }

    return (
        <div id={element.id}>
            <h1>
                {element.title}
            </h1>
            {
                element.description.map(el => 
                    <p className='justify'>{el}</p>
                )
            }

            <p>
                <RLink className='icon_link' style={{display: element.more_details === true ? 'block' : 'none', textDecoration: 'none'}} to={() => getMeRouterObject(location.pathname + '/' + element.id, 'services', element)} onClick={() => scrollTo('services-details', -150)}>
                    {
                        text_content.others.read_more
                    }
                </RLink>
            </p>
        </div>
    )
}

export default Service
