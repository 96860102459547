import React from 'react'
import logo from '../../resources/logo_noT.png'

import { 
    GoogleMap,
    withScriptjs, 
    withGoogleMap,
    Marker,
} from 'react-google-maps'

function InitialMap() {
    return (
        <GoogleMap
            defaultZoom={15}
            defaultCenter={{lat: 46.565968, lng: 24.550976}}
        >
            <Marker position={{lat: 46.565968, lng: 24.550976}}
                icon={{
                    url: logo,
                    scaledSize: new window.google.maps.Size(100, 30)
                }}
            />
            <Marker position={{lat: 46.565968, lng: 24.550976}} />
        </GoogleMap>
    )
}

const WrappedMap = withScriptjs(withGoogleMap(InitialMap));

const Map = () => {
    return (
        <div id="google-map-id">
            {/* <WrappedMap 
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_KEY}`} 
                loadingElement={<div style={{ height: '100%' }} />}
                containerElement={<div style={{ height: '100%' }} />}
                mapElement={<div style={{ height: '100%' }} />} 
            /> */}
            <div className='mapouter'>
                <div className='gmap_canvas'>
                    <iframe id='gmap_canvas'
                    title='ESTTUB LOCATION'
                    src={"https://maps.google.com/maps?q=46.565968,%2024.550976&t=&z=13&ie=UTF8&iwloc=&output=embed"}
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Map
