import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

const Notice = () => {
    const location = useLocation()
    const [title, setTitle] = useState()
    const [filePath, setFileLocation] = useState()
    
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        if(location.state) {
            if(location.state.id === 'notice') {
                setTitle(location.state.title)
                setFileLocation(require(`../../resources/documents/${location.state.content.title}.${location.state.content.type}`).default)
            }
        }
    }, [location.pathname])

    const down = (e) => {
        if(pageNumber - 1 === 0)
            setPageNumber(numPages) 
        else
            setPageNumber(pageNumber - 1)
    }

    const up = (e) => {
        if(pageNumber + 1 > numPages)
            setPageNumber(1) 
        else
            setPageNumber(pageNumber + 1)
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    return (
        <div id='notice-id'>
            <h1>{title}</h1>
            <div>
                <Document
                    file={filePath}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </div>
            <div id='pdf-pag' className='prevent-text-selection'>
                <div style={{cursor: 'pointer'}} onClick={down}><FaAngleLeft /></div>
                <p>Page {pageNumber} of {numPages}</p>
                <div style={{cursor: 'pointer'}}  onClick={up}><FaAngleRight /></div>
            </div>
        </div>
    )
}

export default Notice
