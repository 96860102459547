import React from 'react'
import { useState } from 'react'

import { 
    TextField,
    Button,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Fab
} from '@material-ui/core'

import InProgress from './InProgress'
import AddIcon from '@material-ui/icons/Add';

import { text_content } from '../../resources/texts/site_content'

const ContactForm = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [text, setText] = useState('')
    const [category, setCategory] = useState('');
    const [post, setPost] = useState('');
    const [company, setCompany] = useState('');
    const [file, setFile] = useState(null);

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    const handlePostChange = (event) => {
        setPost(event.target.value);
    }

    const handleUploadClick = (event) => {
        const files = Array.from(event.target.files)
        setFile(files[0])
    }

    const lastValidation = (email, phone) => {

        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        let stop = false

        if(reg.test(String(email).toLowerCase()) === false) {
            console.log('Invalid email format')
            setEmail('')
            stop = true
        }

        reg = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g

        if(reg.test(String(phone).toLowerCase()) === false) {
            console.log('Invalid phone format')
            setPhone('')
            stop = true
        }

        return stop
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        //if(lastValidation(email, phone)) return

        let formData = new FormData()
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        formData.append('category', category)
        formData.append('post', post)
        formData.append('company', company)
        formData.append('file', file)
        formData.append('subject', subject)
        formData.append('text', text)

        const res = await fetch('http://localhost:30001/send-email', {
            method: 'POST',
            body: formData
        })
        
        const res_data = await res.json()

        //resetForm()
    }

    const resetForm = () => {
        setName('')
        setEmail('')
        setPhone('')
        setSubject('')
        setText('')
    }

    const validateForm = (e, v_type, v_setter) => {
        let value = e.target.value, reg, cond = false
        
        switch(v_type) {
            case 'name':
                reg = new RegExp("[^a-zA-Z\\- '`]+")
                cond = true
            break;

            case 'phone':
                reg = new RegExp('^[0-9]+$')
                cond = false
            break;
        }

        if(value !== '' && reg.test(String(value).toLowerCase()) === cond)
            return
        
        v_setter(value)
    }

    return (
        <div id='contact-form' className='contact-form-dark'>
            <InProgress display={true} alt={text_content.contact.alt} />

            <form onSubmit={onSubmit} >
                <div className='txt-field'>
                    <TextField label='Nume' variant='outlined' value={name} onChange={(e) => validateForm(e, 'name', setName)} 
                    inputProps={{
                        maxLength: 50
                    }} />
                </div>

                <div id='em_tel_sec' class='tf-inline'>
                    <div className='txt-field'>
                        <TextField label='E-mail' variant='outlined' value={email} onChange={(e) => setEmail(e.target.value)} 
                        inputProps={{
                            maxLength: 50
                        }} />
                    </div>

                    <div className='txt-field'>
                        <TextField label='Telefon' variant='outlined' value={phone} onChange={(e) => validateForm(e, 'phone', setPhone)} 
                        inputProps={{
                            maxLength: 25
                        }} />
                    </div>
                </div>

                <div class='tf-inline'>
                    <div className='txt-field'>
                        <FormControl variant="outlined">
                            <InputLabel id="demo-simple-select-outlined-label">Categorie</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={category}
                                onChange={handleCategoryChange}
                                label="Categorie"
                                >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'Angajare'}>Angajare</MenuItem>
                                <MenuItem value={'Cerere serviciu'}>Cerere serviciu</MenuItem>
                                <MenuItem value={'Altele'}>Altele</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    {
                        category === 'Angajare' ?
                        <div className='txt-field'>
                            <FormControl variant="outlined">
                                <InputLabel id="demo-simple-select-outlined-label">Post</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={post}
                                    onChange={handlePostChange}
                                    label="Post"
                                    >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={'Muncitor'}>Job1</MenuItem>
                                    <MenuItem value={'Inginer'}>Job2</MenuItem>
                                    <MenuItem value={'Altele'}>Altele</MenuItem>
                                </Select>
                            </FormControl>
                        </div> : category !== '' ?
                        <div className='txt-field'>
                            <TextField 
                                multiline
                                rowsMax={3} 
                                label='Companie' variant='outlined' value={company} onChange={(e) => setCompany(e.target.value)} 
                                inputProps={{
                                    maxLength: 125
                                }} />
                        </div> : ''
                    }
                </div>

                {
                    category !== '' ?
                    <div class='attach-container'>
                        <input
                            accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={handleUploadClick}
                            style={{display: 'none'}}
                            />

                            <label htmlFor="contained-button-file">
                                <Fab size="small" aria-label="add" component="span">
                                    <AddIcon />
                                </Fab>
                            </label>

                            <div style={{display: 'inline-block', marginLeft: '15px'}}>
                            {
                                file != null ? <p>{file.name}</p> : <p>Alege fisier</p>
                            }
                            </div>
                    </div>
                    : ''
                }

                <div className='txt-field'>
                    <TextField 
                        multiline
                        rowsMax={3} 
                        label='Subiect' variant='outlined' value={subject} onChange={(e) => setSubject(e.target.value)} 
                        inputProps={{
                            maxLength: 125
                        }} />
                </div>

                <div className='txt-field'>
                    <TextField
                        label='Trimite-ne un mesaj' 
                        multiline
                        rows={7}
                        rowsMax={7}
                        variant='outlined' 
                        value={text} onChange={(e) => setText(e.target.value)}
                        inputProps={{
                            maxLength: 350
                        }} />
                </div>
                
                <Button type="submit" variant='contained' color='primary'>Trimite</Button>
            </form>
        </div>
    )
}

export default ContactForm
