import React from 'react'
import { languages } from '../../resources/texts/site_content'

const LanguageSelector = () => {
    const langSelect = (lang) => {
        window.sessionStorage.setItem('language', lang)
        window.location.reload(false)
    }

    return (
        <div>
            {
                languages.map(el =>
                    <div title={el.title} className='flag' onClick={() => langSelect(el.lang)}>
                        <img src={el.flag} alt={el.alt} /> 
                    </div>
                )
            }
        </div>
    )
}

export default LanguageSelector
