import Home from '../../components/body/Home'
import Contact from '../../components/body/Contact'
import Services from '../../components/body/Services'
import Experience from '../../components/body/Experience'
import ServiceDetails from '../../components/body/ServiceDetails'

import { text_content } from '../../resources/texts/site_content'

function getRoutes() {

    let servicesSub = []
    
    text_content.services.services.forEach(el => {
        let obj = {
            path: el.id,
            component: ServiceDetails,
            parent: 'services',
            title: el.title,
            sub: {}
        }
        servicesSub.push(obj)
    })

    const routes = {
        home: {
            path: '',
            component: Home,
            parent: null,
            title: text_content.home.title,
            sub: {}
        },
        services: {
            path: 'services',
            component: Services,
            parent: null,
            title: text_content.services.title,
            sub: servicesSub,
        },
        experience: {
            path: 'experience',
            component: Experience,
            parent: null,
            title: text_content.experience.title,
            sub: {}
        },
        contact: {
            path: 'contact',
            component: Contact,
            parent: null,
            title: text_content.contact.title,
            sub: {}
        },
    }

    return routes
}

const routes = getRoutes()

export { routes, getRoutes }