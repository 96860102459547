import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

//import { useState, useEffect } from 'react'

import {
    Button,
} from '@material-ui/core'

//const AlbumSlider = ({album, openFullViewImage}) => {
class AlbumSlider extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            image_height: 300,
            carousel_items: 3,
            carousel_margin: 20
        }
        
        this.openView = this.openView.bind(this)
        this.handleSliderHeight = this.handleSliderHeight.bind(this)
    }

    /*const [image_height, setImageHeight] = useState(300)
    const [carousel_items, setItems] = useState(3)
    const [carousel_margin, setMargin] = useState(20)*/

    componentDidMount() {
        this.handleSliderHeight()
        window.addEventListener('resize', this.handleSliderHeight)
    }

    handleSliderHeight = () => {
        const pc = document.getElementsByClassName('page-container')
        Array.from(pc).forEach(element => {
            element.style.width = window.innerWidth
        });
        if(window.innerWidth > 750) {
            this.setState({carousel_items: 3})
        }
        if(window.innerWidth <= 750) {
            this.setState({carousel_items: 2})
        }
        if(window.innerWidth <= 550) {
            this.setState({carousel_items: 1})
        }
        const car = document.getElementById('owl-car-id')
        if(!car)
            return
        let val = (car.offsetWidth - this.state.carousel_items * this.state.carousel_margin) / this.state.carousel_items;
        this.setState({image_height: val - 100})
    }
    
    openView = (img, index) => {
        const obj = {
            album: this.props.album,
            img: img,
            index: index
        }
        this.props.openFullViewImage(obj)
    }

    render() {
        return (
            <>
            {
                this.props.album.length > 0 ?
                <OwlCarousel id='owl-car-id' className='owl-theme' 
                    items={this.state.carousel_items} 
                    loop={false}
                    margin={this.state.carousel_margin} 
                    nav={false} 
                    dots={false}>
                    {
                        this.props.album.map((el, index) => 
                            <div class='item'>
                                <img class='service-carousel-image' src={el.src} alt='Esttub Foraje' />
                                <Button onClick={() => this.openView(el.src, index)}>Marire</Button>
                            </div>
                        )
                    }
                </OwlCarousel>
                : <p>Empty</p>
            }
            </>
        )
    }
}

export default AlbumSlider
