
import { text_content } from '../../resources/texts/site_content'
import { servicesWithAlbum } from '../../resources/texts/site_content'
import Service from './Service'
import AccordionSlider from './AccordionSlider'

import { pure } from 'recompose'

import { useState } from 'react'

const Services = pure(({openFullViewImage}) => {
    
    const [expanded, setExpanded] = useState('')

    const handleExpanded = (val) => {
        setExpanded(val !== expanded ? val : '')
    } 

    return (
        <div id='services-container'>
            <div>
                <div className='page-container'>
                    <div id='services-text'>
                        <h1>{text_content.services.title}</h1>
                        <p className='justify'>
                            {text_content.services.text}
                        </p>
                    </div>
                </div>
            </div>

            {
                servicesWithAlbum.map(el => 
                    <div>
                        <div className='page-container'>
                            <div class='service-container'>
                                <Service element={el} />

                                {
                                    el.album.length > 0 ?
                                    <AccordionSlider expandedValue={expanded} handleExpanded={handleExpanded} element={el} openFullViewImage={openFullViewImage} />
                                    : ''
                                }

                                {
                                    el.sub.map(sel => 
                                        sel.album.length > 0 ? 
                                        <AccordionSlider expandedValue={expanded} handleExpanded={handleExpanded} element={sel} openFullViewImage={openFullViewImage} />
                                        : ''
                                    )
                                }

                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
})

export default Services
