import services_album from '../albums/scripts/services-album.json'
import languagesJson from './languages.json'

const setLanguages = () => {
    let res = []
    languagesJson.forEach(el => {
        let obj = el
        obj.flag = require('../flags/' + el.flag).default
        res.push(obj)
    })
    return res
}

const setLanguage = (opposite = false) => {
    let text_content
    let lang = window.sessionStorage.getItem('language')
    if(lang === null) {
        window.sessionStorage.setItem('language', 'ro')
        text_content = require('./text_content_ro.json')
    }else {
        if(opposite === true) {
            if(lang === 'ro')
                lang = 'en'
            else
                lang = 'ro'
        }
        text_content = require('./text_content_' + lang + '.json')
    }
    return text_content
}

const importAll = () => {
    const modules = []
    const text_content = setLanguage()

    text_content.services.services.forEach(element => {
        modules.push(
            {
                id: element.id,
                title: element.title,
                src: require('../albums/thumbnails/' + element.thumbnail).default,
                preview: element.preview
            }
        )
    })

    return modules;
}

const setSubAlbums = (sub) => {

    let aux = []
    if(sub !== []) {
        sub.forEach(el => {
            let auxObj = el
            let auxArray = []
            if(services_album[el.id] && services_album[el.id].album) {
                services_album[el.id].album.forEach(img => {
                    let obj = {
                        id: img.id,
                        src: require('../albums/' + el.album_folder + services_album[el.id].optimized + img.name).default,
                    }
                    auxArray.push(obj)
                })
            }
            auxObj.album = auxArray
            aux.push(auxObj)
        })
    }
    return aux
}

const setAlbumsForElement = (element) => {
    let album_images = []
    if(services_album[element.id] && services_album[element.id].album) {
        services_album[element.id].album.forEach(img => {
            let obj = {
                id: img.id,
                src: require('../albums/' + element.album_folder + services_album[element.id].optimized + img.name).default,
            }
            album_images.push(obj)
        })
    }
    return album_images
}

const importAlbum = () => {
    const modules = []
    const text_content = setLanguage()

    text_content.services.services.forEach(element => {
        let album_images = []
        
        if(services_album[element.id] && services_album[element.id].album) {
            services_album[element.id].album.forEach(img => {
                let obj = {
                    id: img.id,
                    src: require('../albums/' + element.album_folder + services_album[element.id].optimized + img.name).default,
                }
                album_images.push(obj)
            })
        }
        
        modules.push(
            {
                id: element.id,
                title: element.title,
                src: require('../albums/thumbnails/' + element.thumbnail).default,
                description: element.description,
                sub: setSubAlbums(element.sub),
                album: album_images,
                more_details: element['more-details']
            }
        )
    })

    return modules;
}

const getMeDetails = (obj) => {
    const text_content = setLanguage()
    let service = {}
    text_content.services.services.forEach(el => {
        if(el.id === obj.id) {
            service = el
        }
    })

    obj.details = service.details
    obj.title = service.title
    obj.description = service.description
    obj.sub = service.sub

    return obj
}

const getMeDetailsById = (id) => {

    const text_content = setLanguage()
    let service = {}
    text_content.services.services.forEach(element => {
        if(element.id === id) {

            service.id = element.id
            service.description = element.description
            service.more_details = element['more-details']
            service.details = element.details
            service.title = element.title
            service.src = require('../albums/thumbnails/' + element.thumbnail).default
            service.album = setAlbumsForElement(element)
            service.sub = setSubAlbums(element.sub)
        }
    })

    return service
}

const services = importAll()
const servicesWithAlbum = importAlbum()
const text_content = setLanguage()
const languages = setLanguages()

export { services, servicesWithAlbum, text_content, languages, getMeDetails, getMeDetailsById }