
export const getMeRouterObject = (path, parent, content, id = 'classic') => {
    return {
        pathname: path,
        state: {
            id: id,
            parent: parent,
            content: content
        }
    }
}