import React from 'react'

import { text_content } from '../../resources/texts/site_content'

const InProgress = ({display, alt}) => {
    return (
        <div id='in-progress' style={{display: display ? 'block': 'none'}}>
            <div>
                <p>{text_content.in_progress.notice}</p>
                <p>{alt}</p>
            </div>
        </div>
    )
}

export default InProgress
