import React from 'react'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AlbumSlider from './AlbumSlider'

import SliderGifAnim from './SliderGifAnim'

const AccordionSlider = ({expandedValue, handleExpanded, element, openFullViewImage}) => {

    const onChangeAcc = (id) => {
        handleExpanded(id)
    }
    
    return (
        <Accordion expanded={expandedValue === element.id} TransitionProps={{ unmountOnExit: true }} onChange={() => onChangeAcc(element.id)} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                    <div>
                        <h6>{ element.title }</h6>
                    </div>
            </AccordionSummary>

            <AccordionDetails>
                <AlbumSlider album={element.album} openFullViewImage={openFullViewImage} />
                <SliderGifAnim expandedValue={expandedValue} accID={element.id} />
            </AccordionDetails>
        </Accordion>
    )
}

export default AccordionSlider
