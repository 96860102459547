
import images from './details.json'

const importAll = () => {
    const modules = []
    let lang = window.sessionStorage.getItem('language')
    lang = lang ? lang : 'ro'

    images.forEach(el => {
        modules.push(
            {
                src: require('../' + el.Path).default,
                title: el.Title[lang],
                description: el.Description,
                show: el.Show
            }
        )
    })

    return modules;
}

const sliderImages = importAll()

export default sliderImages