import React from 'react'

import { text_content } from '../../resources/texts/site_content'

import { Link as RLink } from 'react-router-dom'
import { FaAngleDown, FaHome, FaHammer , FaPhone, FaDiagnoses } from 'react-icons/fa'
import {
    Events,
    scrollSpy,
    scroller
} from "react-scroll"
import Dropdown from './Dropdown'

import { getMeRouterObject } from '../../resources/routes/RouteMethods'

class Menu extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        Events.scrollEvent.register("begin", function() {
            //console.log("begin", arguments);
        });
  
        Events.scrollEvent.register("end", function() {
            //console.log("end", arguments);
        });

        scrollSpy.update();
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    scrollTo (offset, avoidHiding = false) {
        if(this.props.isTransitionMenu === true && avoidHiding === false) {
            this.props.hideMenu()
        }else {
            if(this.props.isTransitionMenu === true)
                return
        }

        scroller.scrollTo('page-main-content', {
            duration: 300,
            smooth: true,
            spy: true,
            offset: -170
        })
    }

    render() {
        return (
            <div className='navbar-items prevent-text-selection'>
                <div>
                    <RLink to={() => getMeRouterObject('/', null, null)} onClick={() => this.scrollTo(-170)}>
                        <div id={this.props.home_id}>
                            <div>
                                <FaHome className='icon-left' />
                                <p>{text_content.home.title}</p>
                            </div>
                        </div>
                    </RLink>
                </div>

                <div style={{position: 'relative'}}>
                    <RLink to={() => getMeRouterObject('/services', null, null)} onClick={() => this.scrollTo(0, true)}>
                        <div>
                            <div>
                                <FaHammer className='icon-left' />
                                <p>{text_content.services.title}</p>
                                <FaAngleDown className='icon-right' />
                            </div>
                        </div>
                    </RLink>
                    <Dropdown id={'services'} elements={text_content.services.services} isTransitionMenu={this.props.isTransitionMenu} hideMenu={this.props.hideMenu} />
                </div>

                <div>
                    <RLink to={() => getMeRouterObject('/experience', null, null)} onClick={() => this.scrollTo(0)}>
                        <div>
                            <div>
                                <FaDiagnoses className='icon-left' />
                                <p>{text_content.experience.title}</p>
                            </div>
                        </div>
                    </RLink>
                </div>

                <div>
                    <RLink to={() => getMeRouterObject('/contact', null, null)} onClick={() => this.scrollTo(0)}>
                        <div id={this.props.last_id}>
                            <div>
                                <FaPhone className='icon-left' />
                                <p>{text_content.contact.title}</p>
                            </div>
                        </div>
                    </RLink>
                </div>
            </div>
        )
    }
}

export default Menu
