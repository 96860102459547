import React from 'react'
import Card from 'react-bootstrap/Card'
import { Link as RLink } from 'react-router-dom'
import {
    Button,
} from '@material-ui/core'
import {
    scroller
} from "react-scroll"
import { getMeRouterObject } from '../../resources/routes/RouteMethods'

import { sleep } from '../../resources/globals/methods'

import { text_content } from '../../resources/texts/site_content'

const CardCustom = ({element, image_height, image_width}) => {

    const scrollTo = async (goTo, offset) => {
        await sleep(200)
        
        scroller.scrollTo(goTo, {
            duration: 300,
            smooth: true,
            spy: true,
            offset: offset
        });
    }
    
    return (
        <div>
            <Card>
                <Card.Img variant="top" 
                className='card-image' 
                src={element.src} 
                style={{height: `${image_height}px`}} alt='Image' />
                
                <Card.Body>
                    <Card.Title>{element.title}</Card.Title>
                    <Card.Text>
                        {element.preview}
                    </Card.Text>
                    
                    <Button variant="primary">
                        <RLink style={{color: 'white', textDecoration: 'none'}} to={() => getMeRouterObject('/services', null, null)} onClick={() => scrollTo(element.id, -100)}>
                            {
                                text_content.others.visualize
                            }
                        </RLink>
                    </Button>
                </Card.Body>
            </Card>
        </div>
    )
}

export default CardCustom
